import { createTheme } from 'baseui'
import { Font } from 'baseui/theme'
import { colors as baseuiColors } from 'baseui/tokens'

import {
  CustomTheme,
  CustomThemeColors,
  CustomThemeTypography,
  EvodicomTheme,
} from '../../app/core/types/types'
import { generatePalette } from '../../app/core/utils/styling'
import { evodicomResponsive } from './base'

const primaryColor = '#002e7a'
const primaryAccent = '#0066ff'
const secondaryColor = '#6699ff'
const mutedColor = '#6c757d'

const informationColor = '#43baff'
const positiveColor = '#4bd48b'
const warningColor = '#f9af3b'
const negativeColor = '#f5592f'

const primaryColorPalette = generatePalette(primaryColor, 'primary')
const primaryAccentPalette = generatePalette(primaryAccent, 'accent')
const secondaryColorPalette = generatePalette(secondaryColor, 'secondary')

const informationColorPalette = generatePalette(informationColor, 'infomation')
const positiveColorPalette = generatePalette(positiveColor, 'positive')
const warningColorPalette = generatePalette(warningColor, 'warning')
const negativeColorPalette = generatePalette(negativeColor, 'negative')

const primaryFontFamily = '"Avenir LT Std", "Times New Roman", sans-serif'
const fontWeightLight = 300
const fontWeightNormal = 500
const fontWeightBold = 900

const font300: Font = {
  fontFamily: primaryFontFamily,
  fontSize: '16px',
  fontWeight: 'normal',
  lineHeight: '24px',
}

const font350: Font = {
  fontFamily: primaryFontFamily,
  fontSize: '16px',
  fontWeight: 500,
  lineHeight: '20px',
}

const font750: Font = {
  fontFamily: primaryFontFamily,
  fontSize: '28px',
  fontWeight: 500,
  lineHeight: '36px',
}

const font1350: Font = {
  fontFamily: primaryFontFamily,
  fontSize: '52px',
  fontWeight: 500,
  lineHeight: '64px',
}

const typography = {
  fontWeightLight,
  fontWeightNormal,
  fontWeightBold,

  Body: font300,
  Paragraph: font300,
  Label: font350,
  Heading: font750,
  Display: font1350,
} as CustomThemeTypography

const colors = {
  muted: mutedColor,
  ...secondaryColorPalette,
  ...informationColorPalette,
} as CustomThemeColors

const { breakpoints, mediaQuery } = evodicomResponsive

const defaultTheme: EvodicomTheme = createTheme<CustomTheme>(
  {
    primaryA: baseuiColors.gray700,
    ...primaryColorPalette,
    ...primaryAccentPalette,
    ...positiveColorPalette,
    ...warningColorPalette,
    ...negativeColorPalette,
    primaryFontFamily,
  },
  {
    typography,
    colors,
    breakpoints,
    mediaQuery,
  },
)

export default defaultTheme
