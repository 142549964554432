import React, { ReactNode } from 'react'
import { useStyletron } from 'baseui'
// import { Avatar } from 'baseui/avatar'
// import { Button } from 'baseui/button'

import { expandPadding } from '../../../core/utils/expand-css'
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
// import { faFileSignature, faUser } from '@fortawesome/free-solid-svg-icons'

import { AppLogo } from './styled-components'
import logo from '../../../../img/logo-inv.png'
import UserMenu from '../UserMenu'

export interface PageHeaderProps {
  subheader?: ReactNode
}

const appLogo = <AppLogo src={logo} />
// const UsersIcon = () => <FontAwesomeIcon icon={faUser} />
// const FileSigIcon = () => <FontAwesomeIcon icon={faFileSignature} />

// const userMenuItems = [
//   { label: 'Mi Perfil', icon: UsersIcon },
//   { label: 'Plantillas de Reporte', icon: () => <FontAwesomeIcon icon={faFileSignature} /> },
// ]
const PageHeader = () => {
  const [css, $theme] = useStyletron()

  return (
    <header>
      <nav
        id="nav"
        style={{
          width: '100%',
          backgroundImage: `linear-gradient(to right, ${$theme.colors.primary}, ${$theme.colors.accent})`,
          boxSizing: 'border-box',
        }}
      >
        <div
          id="wrapper"
          style={{
            width: '100%',
            boxSizing: 'border-box',
            ...expandPadding('0.5rem 1rem'),
          }}
        >
          <div
            className={css({
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              maxWidth: '1407px',
              marginRight: 'auto',
              marginLeft: 'auto',
              paddingRight: '1rem',
              paddingLeft: '1rem',
              [$theme.mediaQuery.medium]: {
                paddingRight: '3rem',
                paddingLeft: '3rem',
              },
            })}
          >
            {appLogo}
            <UserMenu username="Bernardo Jalvanera" />
          </div>
        </div>
      </nav>
    </header>
  )
}

export default PageHeader
