import { styled } from '../../core/utils/styling'
import { expandFlex } from '../../core/utils/expand-css'

export const Page = styled('div', () => ({
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
}))

export const PageContent = styled('main', () => ({
  ...expandFlex('1 1 auto'),
  position: 'relative',
  overflowY: 'auto',
}))

export const PageFooter = styled('footer', () => ({
  ...expandFlex('0 0 auto'),
  backgroundColor: '#cccccc',
  height: '2rem',
}))
