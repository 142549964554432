import { Card } from 'baseui/card'
import { LabelMedium as Label } from 'baseui/typography'
import { StyledBodyCell, StyledTable as TableGrid } from 'baseui/table-grid'
import { StyletronComponent } from 'styletron-react'

import { override, withStyle } from '../../core/utils/styling'
import { expandPadding } from '../../core/utils/expand-css'

export const StyledCard = override(Card, ({ $theme }) => ({
  Root: {
    style: {
      height: '100vh',
      overflowY: 'auto',
    },
  },
  Title: {
    style: {
      fontWeight: $theme.typography.fontWeightBold,
      ...expandPadding('0'),
    },
  },
}))

export const StyledTitle = override(Label, ({ $theme }) => ({
  Block: {
    style: {
      marginBottom: '1rem',
      color: $theme.colors.muted,
      borderBottom: `1px solid ${$theme.colors.primaryB}`,
    },
  },
}))

export const StyledTable = withStyle(TableGrid, () => ({ marginBottom: '2rem' }))

/* eslint-disable @typescript-eslint/indent */
export const ColoredBodyCell = withStyle<
  StyletronComponent<any>,
  { $color: string; $isLight: boolean }
>(StyledBodyCell, ({ $color, $isLight, $theme }) => ({
  backgroundColor: $color,
  color: $isLight ? $theme.colors.primaryA : $theme.colors.primaryB,
}))
