import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import ThemeProvider from './core/contexts/ThemeProvider'

import Studies from './pages/studies'
import StyleGuide from './pages/style-guide'

const baseUrl = process.env.PUBLIC_URL

const App = () => (
  <ThemeProvider>
    <BrowserRouter>
      <Switch>
        <Route exact path={`${baseUrl}/`}>
          <Studies />
        </Route>
        <Route exact path={`${baseUrl}/style-guide`}>
          <StyleGuide />
        </Route>
      </Switch>
    </BrowserRouter>
  </ThemeProvider>
)

export default App
