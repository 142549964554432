// @ts-ignore: no .d.ts declaration available and I don't feel like creating one
import { expandProperty } from 'inline-style-expand-shorthand'

type ExpandableProperties =
  | 'border'
  | 'borderTop'
  | 'borderRight'
  | 'borderBottom'
  | 'borderLeft'
  | 'borderWith'
  | 'borderStyle'
  | 'borderColor'
  | 'padding'
  | 'margin'
  | 'outline'
  | 'flex'

function curry(property: ExpandableProperties): (values: string) => object {
  return function expand(values: string) {
    return expandProperty(property, values)
  }
}

// exports convenience functions used to expand css properties
// not all supported properties contain an export.
// if an export for a particular property is not here, create one

export const expandFlex = curry('flex')
export const expandBorder = curry('border')
export const expandPadding = curry('padding')
export const expandMargin = curry('margin')
