import React, { PropsWithChildren, ReactNode } from 'react'

import PageHeader from './PageHeader'
import { Page, PageContent, PageFooter } from './styled-components'

export interface DefaultLayoutProps {
  pageTitle?: string
  subheader?: ReactNode
}

const Layout = ({ children }: PropsWithChildren<{}>) => {
  return (
    <Page>
      <PageHeader />
      <PageContent>{children}</PageContent>
      <PageFooter>THIS IS PAGE FOOTER HERE</PageFooter>
    </Page>
  )
}

export default Layout
