// @ts-ignore: No .d.ts declaration file found
import { getMediaQuery } from 'baseui/helpers/responsive-helpers'
import { CustomBreakpoints, CustomMediaQuery } from '../../app/core/types/types'

interface EvodicomResponsive {
  breakpoints: CustomBreakpoints & { [key: string]: number }
  mediaQuery: CustomMediaQuery & { [key: string]: string }
}

export const breakpoints: { [k: string]: number } = {
  small: 769,
  medium: 1024,
  large: 1360,
  xlarge: 1600,
}

const initial: EvodicomResponsive = {
  breakpoints: {
    xlarge: 0,
    large: 0,
    medium: 0,
    small: 0,
  },
  mediaQuery: {
    xlarge: '',
    large: '',
    medium: '',
    small: '',
  },
}

export const evodicomResponsive = Object.keys(breakpoints).reduce((acc, key) => {
  acc.breakpoints[key] = breakpoints[key] as number
  acc.mediaQuery[key] = getMediaQuery(breakpoints[key])
  return acc
}, initial)
