import React from 'react'
import { faChevronDown } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Block } from 'baseui/block'

import { UserMenuButton, Avatar } from './styled-components'

export interface UserMenuProps {
  username: string
}

const UserMenu = ({ username }: UserMenuProps) => {
  return (
    <UserMenuButton id="my-id">
      <Avatar name={username} />
      <Block marginLeft="0.875rem" display="flex" justifyContent="center" alignContent="center">
        <FontAwesomeIcon icon={faChevronDown} size="sm" />
      </Block>
    </UserMenuButton>
  )
}

export default UserMenu
