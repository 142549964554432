import React, { Fragment } from 'react'
import { StyledHeadCell, StyledBodyCell } from 'baseui/table-grid'

import { ColoredBodyCell, StyledTable, StyledTitle } from './styled-components'
import { isHexColor, isLightColor } from '../../core/utils/styling'

export interface PaletteTableProps {
  name: string
  colors: any
}

const PaletteTable = ({ name, colors }: PaletteTableProps) => {
  return (
    <>
      <StyledTitle>{`${name[0].toUpperCase()}${name.substring(1)}`} palette</StyledTitle>
      <StyledTable $gridTemplateColumns="50% 50%">
        <StyledHeadCell>Key</StyledHeadCell>
        <StyledHeadCell>Color</StyledHeadCell>

        {Object.keys(colors).map((key) => {
          const color = colors[key]

          return (
            <Fragment key={key}>
              <StyledBodyCell>{`$theme.colors.${key}`}</StyledBodyCell>
              <ColoredBodyCell
                $color={color}
                $isLight={isHexColor(color) ? isLightColor(color) : true}
              >
                {color}
              </ColoredBodyCell>
            </Fragment>
          )
        })}
      </StyledTable>
    </>
  )
}

export default PaletteTable
