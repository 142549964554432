import { createElement } from 'react'
import { createThemedStyled, createThemedUseStyletron, createThemedWithStyle } from 'baseui'

import { EvodicomTheme, OverrideFn } from '../types/types'

export type Color = {
  r: number
  g: number
  b: number
}

export type ColorPalette = {
  [shade: string]: string
}

const factors = {
  50: 20,
  100: 45,
  200: 60,
  300: 82,
  400: 100,
  500: 87,
  600: 70,
  700: 54,
}

function colorToString({ r, g, b }: Color): string {
  /* eslint-disable no-bitwise */
  return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`
}

function stringToColor(hex: string): Color {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)

  if (result === null) {
    throw new Error('Invalid color input. Provide a color in hex notation with or without the #')
  }

  try {
    return { r: parseInt(result[1], 16), g: parseInt(result[2], 16), b: parseInt(result[3], 16) }
  } catch (error) {
    throw new Error('Invalid color input. Provide a color in hex notation with or without the #')
  }
}

function multiplyColor(left: Color, right: Color): Color {
  const result = {
    r: Math.floor((left.r * right.r) / 255),
    g: Math.floor((left.g * right.g) / 255),
    b: Math.floor((left.b * right.b) / 255),
  }

  return result
}

function mergeColor(rgb1: Color, rgb2: Color, factor = 50): string {
  const p = factor / 100 // p as in percentage

  const mixed = {
    r: Math.floor((rgb2.r - rgb1.r) * p + rgb1.r),
    g: Math.floor((rgb2.g - rgb1.g) * p + rgb1.g),
    b: Math.floor((rgb2.b - rgb1.b) * p + rgb1.b),
  }

  return colorToString(mixed)
}

function getColorBrightness(color: Color): number {
  return (color.r * 299 + color.g * 587 + color.b * 114) / 1000
}

const useStyletron = createThemedUseStyletron<EvodicomTheme>()

export function isHexColor(hex: string): boolean {
  return /^#([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/.test(hex)
}

export function isLightColor(hex: string): boolean {
  const color = stringToColor(hex)

  return getColorBrightness(color) > 128
}

export const generatePalette = (baseColor = '#ffffff', paletteName = 'palette'): ColorPalette => {
  const rgb = stringToColor(baseColor)
  const lightMix = stringToColor('#ffffff')
  const darkMix = multiplyColor(rgb, rgb)

  const shades = Object.entries<number>(factors).reduce((colorShades, [shade, factor]) => {
    const reducer = colorShades
    const mixColor = parseInt(shade, 10) <= 400 ? lightMix : darkMix

    reducer[`${paletteName}${shade}`] = mergeColor(mixColor, rgb, factor)

    return reducer
  }, {} as { [key: string]: string })

  return {
    [paletteName]: colorToString(rgb),
    ...shades,
  }
}

export const styled = createThemedStyled<EvodicomTheme>()
export const withStyle = createThemedWithStyle<EvodicomTheme>()

export const override: OverrideFn = (component, overrides) => {
  return (props) => {
    const [, $theme] = useStyletron()
    const p = { ...props, $theme }

    return createElement(component, { overrides: overrides(p), ...props })
  }
}
