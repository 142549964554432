import { AppNavBar } from 'baseui/app-nav-bar'
import { styled, override } from '../../../core/utils/styling'

export const Navbar = override(AppNavBar, ({ $theme }) => ({
  Root: {
    style: {
      backgroundImage: `linear-gradient(to right, ${$theme.colors.primary}, ${$theme.colors.accent})`,
    },
  },
}))

export const AppLogo = styled('img', () => ({
  width: '100px',
  height: 'auto',
}))
