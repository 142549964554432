import React from 'react'
import { StyledBody as CardBody } from 'baseui/card'
import useTheme from '../../core/hooks/useTheme'
import PaletteTable from './PaletteTable'
import { StyledCard } from './styled-components'

const StyleGuide = () => {
  const { theme } = useTheme()
  const themeColors = theme.colors
  return (
    <StyledCard title="Color Palettes" id="this-is-card">
      <CardBody>
        <PaletteTable colors={themeColors} name="All Colors" />
      </CardBody>
    </StyledCard>
  )
}

export default StyleGuide
