import { Avatar as BaseuiAvatar } from 'baseui/avatar'
import { expandBorder, expandMargin, expandPadding } from '../../../core/utils/expand-css'
import { override, styled } from '../../../core/utils/styling'

export const UserMenuButton = styled<'button', { $isFocusVisible?: boolean }>(
  'button',
  ({ $theme, $isFocusVisible }) => ({
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    color: $theme.colors.contentInversePrimary,
    ...expandBorder('0'),
    ...expandPadding('0'),
    ...expandMargin('5px'),
    outline: $isFocusVisible ? `3px solid ${$theme.colors.accent}` : 'none',
    outlineOffset: '-3px',
    WebkitAppearance: 'none',
    cursor: 'pointer',
  }),
)

export const Avatar = override(BaseuiAvatar, ({ $theme }) => ({
  Root: {
    style: {
      backgroundColor: $theme.colors.contentInversePrimary,
      width: $theme.sizing.scale800,
      height: $theme.sizing.scale800,
    },
  },
  Initials: {
    style: {
      color: $theme.colors.contentPrimary,
    },
  },
}))
