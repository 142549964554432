import React, { createContext, PropsWithChildren, useState } from 'react'
import { Client as Styletron } from 'styletron-engine-atomic'
import { Provider as StyletronProvider } from 'styletron-react'
import { BaseProvider } from 'baseui'
import { defaultTheme } from '../../../styles/themes'
import { EvodicomTheme } from '../types/types'
import NOOP from '../utils/no-op'

export interface ThemeContextProps {
  theme: EvodicomTheme
  setTheme: (theme: EvodicomTheme) => void
}

const defaultContextProps: ThemeContextProps = {
  theme: defaultTheme,
  setTheme: NOOP,
}

export const ThemeContext = createContext<ThemeContextProps>(defaultContextProps)

const engine = new Styletron()
const baseProviderOverrides = { AppContainer: { style: { height: '100%' } } }

const ThemeProvider = ({ children }: PropsWithChildren<{}>) => {
  const [theme, setTheme] = useState(defaultTheme)

  return (
    <StyletronProvider value={engine}>
      <BaseProvider theme={theme} overrides={baseProviderOverrides}>
        <ThemeContext.Provider value={{ theme: defaultTheme, setTheme }}>
          {children}
        </ThemeContext.Provider>
      </BaseProvider>
    </StyletronProvider>
  )
}

export default ThemeProvider
